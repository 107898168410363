import React from 'react';
import {CardText, CardSubtitle, CardTitle, CardBody, CardImg, Col, Card} from 'reactstrap';
import './Producto.css';
import FichaProducto from './FichaProducto';


class Producto extends React.Component{
    render(){
        return(
        <Col sm="4">
        <Card id='Card'>
            <CardImg src={this.props.imagen}/>
            <CardBody>
            <CardSubtitle>
                    <b>Linea {this.props.linea} </b>
                </CardSubtitle>
                <CardTitle>
                    {this.props.titulo}
                </CardTitle>

                {/* <CardText dangerouslySetInnerHTML={{__html:this.props.descripcion}} /> */}
                    {/* {this.props.descripcion} */}
                    
                {/* </CardText> */}
                <FichaProducto props={this.props} />
            </CardBody>
        </Card>
        </Col>
    );
}}

export default Producto;