import React from 'react';
import './Hero.css';

class Hero extends React.Component{
    render(){
        return(

        <div className="hero">
        <div className="overlayimg" 
        // style="background-image: url(src/bg3.jpg);"
        ></div>
        
        <div className="hero-content">
            <h1>Vestuario Gastronómico</h1>
            <img src="./3.png" alt="Chef"/>
        </div>
        </div>
        );
    }
}



export default Hero;