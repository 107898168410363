import React from 'react';
import { Navbar, NavbarBrand, Nav, NavLink, NavItem, NavbarToggler, Collapse} from 'reactstrap';
import './Navegacion.css';

class Navegacion extends React.Component{

    constructor(props) {
        super(props);
    
        this.state = {
          isOpen: false
        };
    
        this.toggle = this.toggle.bind(this);
      }
    
      toggle() {
        this.setState({
          isOpen: !this.state.isOpen
        });
      }
      



    render(){


        return(
            <Navbar dark expand="md" >
                <NavbarBrand href='/'><img alt="logo" src="isologo.png"/></NavbarBrand>
                <NavbarToggler
                onClick={this.toggle}
                >
                    <i class="fas fa-bars"></i>
                    </NavbarToggler>
                <Collapse
                isOpen={this.state.isOpen}
                navbar
                >
                <Nav className='ml-auto' navbar>
                <NavItem>
                    <NavLink href='https://www.estilomania.cl'>Inicio</NavLink>
                </NavItem>
                <NavItem>
                    <NavLink href='https://www.estilomania.cl#ubicacion'>Ubicación</NavLink>
                </NavItem>
                <NavItem>
                    <NavLink href='#contacto'>Contacto</NavLink>
                </NavItem>
                </Nav>
                </Collapse>

                {/* <Nav className='ml-auto' navbar>
                <NavItem>
                    <NavLink href='/'>Inicio</NavLink>
                </NavItem>
                <NavItem>
                    <NavLink href='/'>React</NavLink>
                </NavItem>
                <NavItem>
                    <NavLink href='/'>Contacto</NavLink>
                </NavItem>
                </Nav> */}
            </Navbar>

        );
    }
}



export default Navegacion;