import React from 'react';
import './Footer.css';

class Footer extends React.Component{
    render(){
        return(

        <div id="contacto" className="contactanos">
        
        <div className="contact-item">
            <img src="logo230.png" width="230px" alt="Logo Estilomania" />
            <br />
            <br />

            <p>
                <i classname="far fa-clock"></i>
                Lunes a Viernes de 10:00 a 17:00 hrs
            </p>
            <p>
                Horario continuo
            </p>
        </div>

        <div className="contact-item">

            <h4>Datos de contacto</h4>

            <ul className="contact-list">
                <li className="contact-list-item">
                <a href="mailto:contacto@estilomania.cl">
                    <span className="contact-list-item-icon">
                    <i className="far fa-envelope"></i>
                    </span>
                    <span className="contact-list-item-text">contacto@estilomania.cl</span>
                </a>

                </li>
                <li className="contact-list-item">
                <a href="tel:941671098">
                    <span className="contact-list-item-icon">
                    <i className="fas fa-mobile-alt"></i>
                    </span>
                    <span className="contact-list-item-text">
                    +56 9 4167 1098
                    </span>
                </a>

                </li>
                <li className="contact-list-item">
                <a href="http://wa.me/56941671098" target="_blank" rel="noopener noreferrer">
                    <span className="contact-list-item-icon">
                    <i className="fab fa-whatsapp"></i>
                    </span>
                    <span className="contact-list-item-text">
                    +56 9 4167 1098
                    </span>
                </a>
                </li>
                <li className="contact-list-item">
                <span className="contact-list-item-icon">
                    <i className="fas fa-map-marker-alt"></i>
                </span>
                <span className="contact-list-item-text">
                    Calle Santiago 851,
                    <br />
                    Local 6, Villa Alemana
                    <br />
                    Galeria Ña-Kuru, a pasos del mercado
                </span>
                </li>
            </ul>
        </div>



            <div className="contact-item">
                <h4>Redes Sociales</h4>
                <a className="contact-icon" href="https://www.facebook.com/Estilomania.cl" target="_blank" rel="noopener noreferrer">
                    <i className="fab fa-facebook"></i>
                </a>
                <a className="contact-icon" href="https://www.instagram.com/estilomania.cl/" target="_blank" rel="noopener noreferrer">
                    <i className="fab fa-instagram"></i>
                </a>
            </div>

        </div>

        );
    }
}

export default Footer;