import React from 'react';
import './App.css';
import Producto from './Componentes/Producto';
import { Container, Row} from 'reactstrap';
import Navegacion from './Componentes/Navegacion';
import {listaProductos} from './listaProductos.json'
import Hero from './Componentes/Hero'
import Footer from './Componentes/Footer'


class App extends React.Component{
  constructor(){
    super();
    this.state = {
      listaProductos
    };

  }
  render(){
    const arregloComponentes = this.state.listaProductos.map(
      (listaProductos, i) => {
        return (
          <Producto
          key={i}
          titulo={listaProductos.titulo}
          imagen={listaProductos.imagen}
          descripcion={listaProductos.descripcion}
          linea={listaProductos.linea}
          stock={listaProductos.stock}

          />
        )
      }
    );

    return (
    <React.Fragment>
      <Navegacion />
      <Hero/>
      <Container>
      <Row className='main-container'>
      {arregloComponentes}
        </Row>
        </Container>
        <Footer />
    </React.Fragment>
  );}
}

export default App;
