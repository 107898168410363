import React from "react";
import { Button, Container, Modal, ModalHeader, ModalBody, ModalFooter, CardImg } from 'reactstrap';
import './FichaProducto.css';

class FichaProducto extends React.Component{
    constructor(props){
        super(); // super llama a toda la info de la clase que hereda tanto como constructores,metodos y atributos

        this.state= {
            modal:false
        };

        this.toggle = this.toggle.bind(this);


    }
    toggle(){
        this.setState(prevState =>({
            modal: !prevState.modal
        }));
    }

    render(){
        return(
            <Container className="btn-container">
            <Button color="primary" onClick={this.toggle}>Ver Ficha</Button>
            <Modal isOpen={this.state.modal}>
                {/* <ModalHeader>
                    {this.props.props.titulo}
                </ModalHeader> */}

                <ModalBody>
                    <CardImg src={this.props.props.imagen}/>
                    <div className="modal-content">
                        <h5>{this.props.props.titulo}</h5>
                    {/* <p >El detalle del producto seleccionado es el siguiente: </p>
                    {this.props.props.descripcion} */}
                    <div dangerouslySetInnerHTML={{__html:this.props.props.descripcion}}></div>
                    {/* <p>Este producto tiene un valor de <b>{this.props.props.linea}</b> pesos</p> */}
                    {/* <p><b>{this.props.props.stock}</b> unidades disponibles</p> */}
                    </div>

                </ModalBody>
                
                <ModalFooter className="modalFooter">
                    <Button color="primary" onClick={this.toggle}> <a className="contact-btn" href="http://wa.me/56941671098" target="_blank"><i class="fab fa-whatsapp"></i> Contactanos</a></Button>
                    <Button color="secondary" onClick={this.toggle}>Volver atrás</Button>
                </ModalFooter>



            </Modal>
            </Container>
        )
    }
}

export default FichaProducto;